import "./../styles.css";
import aiother from "./ai-other.png";
export default function Solutions() {
  return (
    <div class="content-container">
      <div class="left-half text-content">
        <h2>The AI Infrastructure</h2>
        <br />
        1. State-of-the-art AI-enabled built-in cost optimization tool and other
        infrstructure fine-tuned for lowest cost of ownership
        <br />
        2. Performance: Our infrastructure is fine-tuned for distributed
        computing, concurrencies and hosted on industry leading cloud and
        on-prem GPU/CPU infrstructure
        <br />
        3. Zero Trust Enablement: Our platform is zero trust enabled from
        development to deployment to monitoring
        <br />
        4. Training and Inference Service on Industrial Grade Infrastructure
        <br />
        <br />
        <h2> The Products</h2>
        1. Network Anomaly Detection <br />
        2. LLM Based Knowledge Base <br />
        3. Situational Analysis and Summarization at the Edge (Video/Audo Feed)
        <br />
        4. Conversation Customer Support with fine tuned Models <br />
        <br />
        <h2>Proposed Integration Service</h2>
        1. 5G Connectivity <br />
        2. AI DataCenters and Edge Devices <br />
        3. MLOPS <br />
        4. Data Ops <br />
        5. Workload Refactoring and Migration
        <br />
        6. Cenrtalized Monitoring, Security Assurance and Standardized DevSecOps{" "}
      </div>
      <div class="right-half">
        <img src={aiother} alt="ai-other" class="image-placeholder" />
      </div>
    </div>
  );
}
