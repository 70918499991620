import "./../styles.css";
import ai from "./ai.png";
export default function Home() {
  return (
    <div class="content-container">
      <div class="left-half text-content">
        <h1>Mission Statement</h1>
        The World is undergoing a paradigm shift both technologically and
        <br />
        geo-politically. These changes are bringing increased risks and at the{" "}
        <br />
        same increased opportunities. Government agencies both domestic and in{" "}
        <br />
        the Allied countries are struggling to keep pace with this rapid <br />
        transformation.
        <br />
        Bureacracy,lack of funding,shortage of talents and lack of <br />a
        centralized strategy are some of the key constraints that will hold{" "}
        <br />
        back the transition to this new landscape that the private sector is{" "}
        <br />
        rapidly evolving into. There needs to be a central inititiave across the{" "}
        <br />
        agencies which can consolidate the technologies and at the same time{" "}
        <br />
        build the infrastructure that provides entities to adopt these <br />
        technologies in a safe,ethical and compliant manner and fast. Langleyops{" "}
        <br />
        Mission is to provide a global infrastructure that both defense and{" "}
        <br />
        non-defense agencies can leverage to build mission critical workloads
        and <br />
        plan their furture initiatives. <br />
      </div>
      <div class="right-half">
        <img src={ai} alt="ai1" class="image-placeholder" />
      </div>
    </div>
  );
}
