import Navbar from "./Navbar";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact.js";
import Solutions from "./pages/Solutions";
import Thankyou from "./pages/Thankyou";
import Error from "./pages/Error";
import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <>
      <Navbar />
      <div className="container">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/solutions" element={<Solutions />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/thankyou" element={<Thankyou />} />
          <Route path="/error" element={<Error />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
