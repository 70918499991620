import "./../styles.css";
import coding from "./coding.png";

export default function About() {
  return (
    <div class="content-container">
      <div class="left-half text-content">
        <h1>About Us</h1>
        <p>
          We are a pre-seed Start up waiting for grant by the Department of
          Defense and the White House
        </p>
        <br></br>
        We are building a zero trust enabled, cost-optimized Platform that can
        be deployed to government and critical infrastructure industry.<br></br>
        Our solutions also include integration services for clients for their
        workload migration into a standardized hybrid mutlicloud infrstructure
        which is secured, performant and adheres to the high standard of
        compliance that is demanded at the DoD and by The White House <br></br>
      </div>
      <div class="right-half">
        <img src={coding} alt="coding" class="image-placeholder" />
      </div>
    </div>
  );
}
