//import "./../styles.css";
import coding from "./coding.png";
import React, { useState } from "react";
//import axios from "axios";

//import $ from "jquery";

export default function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    //http://localhost:5000/api/data
    // Make a POST request to your server with the form data

    try {
      const response = await fetch("http://localhost:4000/api/people", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          name,
          email,
          phone,
          message,
        }),
      });

      // If the request is successful, redirect to a new page

      if (response.ok) {
        window.location.href = "/thank-you";
      } else {
        window.location.href = "/error";
      }
    } catch (err) {
      console.log("Error fetching data:", err);
    }
  };

  return (
    <div class="content-container">
      <div class="left-half text-content">
        <div class="container">
          <h2>Investor Relations</h2>
          <p>
            We are selectively accepting seed investors inconjunction with{" "}
            <br />
            the grant commitment.
            <br />
            Please feel free to fill out the form below and we will get in touch
            with you to set up a call for our solutions brief.
            <br />
            Thanks! <br />
          </p>
          <form onSubmit={handleSubmit}>
            <div class="form-group">
              <label for="name">Name:</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                class="form-control"
                id="name"
                name="name"
                required
              />
            </div>
            <div class="form-group">
              <label for="email">Email:</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                class="form-control"
                id="email"
                name="email"
                required
              />
            </div>
            <div class="form-group">
              <label for="Phone">Phone:</label>
              <input
                type="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                class="form-control"
                id="phone"
                name="phone"
                required
              />
            </div>
            <div class="form-group">
              <label for="message">Message:</label>
              <textarea
                class="form-control"
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                name="message"
                rows="5"
                required
              ></textarea>
            </div>
            <button type="submit" class="btn btn-primary">
              Submit
            </button>
          </form>
        </div>
      </div>
      <div class="right-half">
        <img src={coding} alt="ai1" class="image-placeholder" />
      </div>
    </div>
  );
}
